<template>
  <div>
    <div class="air__utils__heading">
      <h5>Planeación de Servicios</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--    Enlaces-->
    <div class="row">
      <div class="col-lg-2 col-md-6 col-sm-12 offset-lg-1 offset-md-0 offset-sm-0">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-4 mb-2 text-center">
              <router-link :to="{ name: 'generation_service_plannings'}">
                <b-iconstack font-scale="2" variant="primary">
                  <b-icon stacked icon="clock-fill"  scale="0.5" shift-v="-2"/>
                  <b-icon stacked icon="calendar" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'generation_service_plannings'}">
              <h6 class="text-block text-secondary">Generación de Servicios</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-12">
        <a @click="NavigationLinkControl('reception_service_plannings')">
          <div class="card">
            <div class="card-title">
              <h1 class="text-block mt-4 mb-2 text-center">
                <b-iconstack font-scale="2" variant="primary">
                  <b-icon stacked icon="person-fill" />
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary" > Recepción de Servicios </h6>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-12">
        <a @click="NavigationLinkControl('discharge_service_plannings')">
          <div class="card">
            <div class="card-title">
              <h1 class="text-block mt-4 mb-2 text-center">
                <b-iconstack font-scale="2" variant="primary">
                  <b-icon stacked icon="truck" />
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary">Descarga de Materiales</h6>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-4 mb-2 text-center">
              <router-link :to="{ name: 'material_control'}">
                <b-iconstack font-scale="2" variant="primary">
                  <b-icon stacked icon="gear-fill" scale="0.5" shift-v="-2"/>
                  <b-icon stacked icon="box" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'material_control'}">
              <h6 class="text-block text-secondary">Control de Materiales</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-12">
        <router-link :to="{ name: 'accounting_service_plannings'}">
          <div class="card">
            <div class="card-title">
              <h1 class="text-block mt-4 mb-2 text-center">
                <b-iconstack font-scale="2" variant="primary">
                  <b-icon stacked icon="graph-up"  scale="0.25" shift-v="3" shift-h="-2"/>
                  <b-icon stacked icon="bar-chart-fill"  scale="0.25" shift-v="-3" shift-h="2"/>
                  <b-icon stacked icon="file-earmark" />
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary">Contabilización</h6>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <!-- Enlaces -->
    <div class="row">
      <div class="col-12">
        <div class="air__utils__heading">
          <h5>Visualización de Servicios</h5>
        </div>
      </div>
    </div>
    <!--    Tabs-->
    <div class="row">
      <div class="col">
        <div class="mb-5">
          <a-tabs defaultActiveKey="1" type="card" size="large">
            <a-tab-pane key="1">
              <span slot="tab">
                <a-icon type="calendar" />
                Servicios
              </span>
              <div class="card">
                <div class="card-body text-center">
                  <services-table-bar @getData="getActiveData" showSearch
                                      :sortByOptions="tableObjectActive.sortByOptions"
                                      @searchValue="tableObjectActive.searchValue = $event"
                                      @sortByValue="tableObjectActive.sortByValue = $event"
                                      @sortDirectionValue="tableObjectActive.sortDirectionValue = $event"
                                      @perPageValue="tableObjectActive.perPageValue = $event"
                                      @currentPageValue="tableObjectActive.currentPageValue = $event"
                  >
                    <template slot="filters">
                      <div class="col-sm-12 col-md-6 col-lg-4" >
                        <globalPlantFilter @onChangeValue="getActiveData"  @filterValue="filterWorkCenter = $event" />
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4" >
                        <b-form-group
                          id="status-group"
                          label="Estatus"
                          label-for="status">
                          <b-form-select v-model="filterStatus" :options="servicePlanningStatus"
                                         id="status" label="Estatus" @change="getActiveData()">
                            <template slot="first">
                              <b-form-select-option value="" >Todas los Estatus</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4" >
                        <b-form-group id="transport-group" label="Tipo Logístico de Transporte" label-for="transport">
                          <b-form-select v-model="filterLogisticTypes" :options="LogisticTypes"
                                         id="transport" label="Tipo Logístico de Transporte" @change="getActiveData()">
                            <template slot="first">
                              <b-form-select-option value="" >Todas los Tipos Logísticos</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                      <!-- Filtros ant-design -->
                      <div class="col-sm-12 col-md-6 col-lg-4" >
                        <customDropdownSelect v-model="filterStage"
                          label="Etapa"
                          resource="servicePlanningsSubstages"
                          optionValue="name"
                          @change="getActiveData()"/>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4" >
                        <!--SELECT -Cliente -->
                        <customDropdownSelect v-model="filterClient"
                          label="Cliente"
                          resource="clients"
                          optionValue="code"
                          @change="getActiveData()">
                          <span slot-scope="{option}">
                            {{ option.code }} - {{ option.name }}
                          </span>
                        </customDropdownSelect>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-4" >
                        <!--SELECT -Ingeniero de Servicio -->
                        <customDropdownSelect v-model="filterUsers"
                          label="Ingeniero de Servicio"
                          resource="users"
                          optionValue="email"
                          @change="getActiveData()"/>
                      </div>
                      <!-- Filtros ant-design -->
                      <!-- Fechas -->
                      <div class="col-sm-6 col-lg-3" >
                        <b-form-group id="from-group" label="Fecha inicial de Programación"  label-for="from">
                          <b-form-datepicker v-model="filterFromDate"
                                             reset-button label-reset-button="Limpiar"
                                             id="from" label="Fecha inicial" placeholder="Desde"/>
                        </b-form-group>
                      </div>
                      <div class="col-sm-6 col-lg-3" >
                        <b-form-group id="to-group" label="Fecha final de Programación"  label-for="to">
                          <b-form-datepicker v-model="filterToDate"
                                             reset-button label-reset-button="Limpiar"
                                             id="to" label="Fecha final" placeholder="Hasta"/>
                        </b-form-group>
                      </div>
                      <div class="col-sm-6 col-lg-3" >
                        <b-form-group id="fromPickup-group" label="Fecha inicial de Recolección"  label-for="fromPickup">
                          <b-form-datepicker v-model="filterFromDatePickup"
                                             reset-button label-reset-button="Limpiar"
                                             id="fromPickup" label="Fecha inicial" placeholder="Desde"/>
                        </b-form-group>
                      </div>
                      <div class="col-sm-6 col-lg-3" >
                        <b-form-group id="toPickup-group" label="Fecha final de Recolección"  label-for="toPickup">
                          <b-form-datepicker v-model="filterToDatePickup"
                                             reset-button label-reset-button="Limpiar"
                                             id="toPickup" label="Fecha final" placeholder="Hasta"/>
                        </b-form-group>
                      </div>
                      <!-- Fechas -->
                    </template>

                  </services-table-bar>
                  <services-table :tableObject="tableObjectActive"
                                  :conditionalColumns="conditionalColumnsFunc"
                                  @Modal="OpenModal" @Filter="getActiveData"
                                  @excessPermanenceFunc="OpenModalExcessPermanence"
                                  @searchInput="searchInput = $event"
                                  @selectedService="nextServiceFolio = $event"
                  />
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2">
              <span slot="tab">
                <a-icon type="experiment" />
                Corrientes
              </span>
              <div class="card">
                <div class="card-body text-center">
                  <services-table-bar @getData="getCurrentData" showSearch
                                      :sortByOptions="currentTable.sortByOptions"
                                      @searchValue="currentTable.searchValue = $event"
                                      @sortByValue="currentTable.sortByValue = $event"
                                      @sortDirectionValue="currentTable.sortDirectionValue = $event">
                    <template slot="filters">
                      <div class="col-sm-12 col-lg-6">
                        <customDropdownSelect v-model="currentFilterWorkCenter"
                          label="Planta"
                          resource="workCenters"
                          optionValue="code"
                          @change="getCurrentData()"/>
                      </div>
                      <div class="col-sm-12 col-lg-6">
                        <customDropdownSelect v-model="currentFilter"
                          label="Corriente"
                          resource="currents"
                          @change="getCurrentData()"/>
                      </div>
                      <div class="col-sm-12 col-lg-6">
                        <b-form-group id="from2-group" label="Fecha estimada de llegada a planta inicial"  label-for="from">
                          <b-form-datepicker v-model="currentFilterFromDate" @input="getCurrentData"
                                             id="from" label="Fecha inicial" placeholder="Desde"/>
                        </b-form-group>
                      </div>
                      <div class="col-sm-12 col-lg-6">
                        <b-form-group id="to2-group" label="Fecha estimada de llegada a planta final"  label-for="to">
                          <b-form-datepicker v-model="currentFilterToDate" @input="getCurrentData"
                                             id="to" label="Fecha final" placeholder="Hasta"/>
                        </b-form-group>
                      </div>
                    </template>
                  </services-table-bar>
                  <servicePlanningMaterialsTable :tableObject="currentTable"
                                                 @Filter="getCurrentData"
                                                 @searchInput="searchInput = $event" />
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import servicesTable from './components/servicesTable'
import servicePlanningMaterialsTable from './components/servicePlanningMaterialsTable'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { apiRestMixin } from '@/mixins/apiRestMixin'
import servicesTableBar from './components/servicesTableBar'
import { Modal } from 'ant-design-vue'
import moment from 'moment'
export default {
  name: 'main-service-plannings',
  components: {
    servicesTable,
    servicesTableBar,
    servicePlanningMaterialsTable,
  },
  mixins: [
    getGeneralMixin, apiRestMixin,
  ],
  computed: {
    ...mapState(['servicePlannings']),
    conditionalColumnsFunc() {
      return (this.filterStatus === 'Exceso de Permanencia') ? ['excessPermanence'] : []
    },
  },
  data() {
    return {
      // *********************************************************
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Planeación de Servicios',
          aIcon: '',
        },
      ],
      resource: 'servicePlannings',
      resourceName: 'Servicios',
      searchInput: null,
      // Constants
      // <!-- Table -->
      // <!-- Services -->
      tableObjectActive: {
        data: [],
        header: 'Tabla de Servicios',
        loading: false,
        // SearchBar
        searchValue: '',
        sortByValue: '',
        sortByDefaultValue: '-sap_programming_code',
        sortDirectionValue: '',
        sortByOptions: [
          { value: '', text: 'Ordenar por', disabled: true },
          { value: 'sap_programming_code', text: 'Folio de Seguimiento' },
        ],
        pagination: {
          current: 0,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        perPageValue: 15,
        currentPageValue: 1,
      },
      filterWorkCenter: '',
      filterUsers: undefined,
      filterLogisticTypes: '',
      filterClient: undefined,
      LogisticTypes: [
        { value: true, text: 'Geocycle' },
        { value: false, text: 'Del Cliente' },
      ],
      filterStage: undefined,
      filterStatus: 'Pendiente',
      // Programacion
      filterFromDate: '',
      filterToDate: '',
      // Recoleccion
      filterFromDatePickup: '',
      filterToDatePickup: '',
      // <!-- Table -->
      // Variables de Modal
      serviceDetailModalShow: false,
      actualService: {},
      // Variables de Modal
      // Variable para acceder a recepción de servicio,  descarga o contabilizxación
      nextServiceFolio: '',
      // <!-- Tabla de Corrientes -->
      currentTable: {
        data: [],
        header: 'Tabla de Corrientes',
        loading: false,
        // SearchBar
        searchValue: '',
        sortByValue: '',
        sortByDefaultValue: 'current',
        sortDirectionValue: '',
        sortByOptions: [
          { value: '', text: 'Ordenar por', disabled: true },
        ],
      },
      currentFilter: undefined,
      currentFilterWorkCenter: undefined,
      currentFilterFromDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      currentFilterToDate: moment().add(4, 'days').format('YYYY-MM-DD'),
      // <!-- Tabla de Corrientes -->
    }
  },
  methods: {
    ...mapActions('servicePlannings', ['SET_SERVICEPLANNINGID', 'GOTO_SERVICEPLANNINGID']),
    // Extracción de Información de los combos
    NavigationLinkControl(option) {
      if (this.nextServiceFolio === '') {
        Modal.error({
          title: 'Error',
          content: 'Selecciona un servicio para acceder a su información',
          okText: 'Ok',
          centered: true,
        })
      } else {
        this.GOTO_SERVICEPLANNINGID({
          id: this.nextServiceFolio,
          name: option,
        })
      }
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    sortHelper(tableObject) {
      let direction, value
      if (tableObject.sortDirectionValue === '') {
        direction = ''
      } else if (tableObject.sortDirectionValue === '-') {
        direction = '-'
      } else {
        direction = ''
      }

      if (tableObject.sortByValue === '') {
        value = tableObject.sortByDefaultValue
      } else {
        value = tableObject.sortByValue
      }
      return direction + value
    },
    async getActiveData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObjectActive.pagination.current = paginationEvent.current
        this.tableObjectActive.pagination.pageSize = paginationEvent.pageSize
      }
      const params = {
        'page[number]': `${this.tableObjectActive.pagination.current}`,
        'page[size]': `${this.tableObjectActive.perPageValue}`,
        sort: this.sortHelper(this.tableObjectActive),
        include: 'workCenters,servicePlanningsMaterials.currents,serviceTypes,servicePlanningsPeriods,shifts,service_planeable,servicePlanningsCarriers.trucks.logisticsShipmentTypes',
      }
      if (this.filterStatus !== '') params['filter[servicePlanningStatus]'] = this.filterStatus
      if (this.filterClient !== '') params['filter[clients]'] = this.filterClient
      if (this.filterStage !== '') params['filter[servicePlanningSubstages]'] = this.filterStage
      if (this.filterFromDate !== '' && this.filterToDate !== '') {
        params['filter[programPeriod]'] = `${this.filterFromDate},${this.filterToDate}`
      }
      if (this.filterFromDatePickup !== '' && this.filterToDatePickup !== '') {
        params['filter[pickUpPeriod]'] = `${this.filterFromDatePickup},${this.filterToDatePickup}`
      }
      if (this.filterUsers !== '') params['filter[users]'] = this.filterUsers
      if (this.filterLogisticTypes !== '') params['filter[own]'] = this.filterLogisticTypes
      if (this.filterWorkCenter !== '') params['filter[workCenters]'] = this.filterWorkCenter
      if (this.tableObjectActive.searchValue) params['filter[keys]'] = this.tableObjectActive.searchValue
      await this.getGeneralAntTable(paginationEvent, this.resource, params, this.tableObjectActive)
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(data = {}) {
      this.serviceDetailModalShow = true
      this.actualService = data
    },
    // <!-- Open & Close Modal Logic  -->
    // Lógica de exceso de permanencia
    OpenModalExcessPermanence(data) {
      console.log(data)
    },
    // <!-- Tabla de Corrientes -->
    async getCurrentData() {
      const params = {
        fromDate: this.currentFilterFromDate,
        toDate: this.currentFilterToDate,
        workCenter: this.currentFilterWorkCenter,
        current: this.currentFilter,
      }
      let dataResponse = []
      dataResponse = await this.getAxiosResource('/materialsFromPeriod', params)
      if (typeof dataResponse === 'object') {
        this.currentTable.data = dataResponse
      }
    },
    // <!-- Tabla de Corrientes -->
  },
  async mounted() {
    this.$store.dispatch('servicePlannings/RESET_DATA')
    await this.$store.dispatch('servicePlannings/SET_CURRENT_SERVICE', {})
    await this.getCurrentData()
    await this.getActiveData()
  },
}
</script>

<style scoped>
.border-bottom {
  border-width:3px !important;
}

</style>
