<template>
  <a-table
    :scroll="{ x: 1300, y: 1000}"
    :dataSource="tableObject.data"
    :columns="columns"
    :loading="tableObject.loading"
    :row-key="record => record.currentGroup.current"
    @change="refreshResults"
    @expand="expand"
  >
    <template slot="title">
      <h5 class="text-block text-default mt-2 mb-4 ml-4 text-center">
        {{ tableObject.header }}
      </h5>
    </template>
    <!--    <p slot="expandedRowRender" slot-scope="record">-->
    <!--    </p>-->
    <div slot="current" slot-scope="data">{{data.current}} </div>
    <div slot="um" slot-scope="data">
      <div v-for="(um,key) in data" :key="key">
        {{key}}
        <hr v-if="Object.keys(data).length > 1" class="bg-dark">
      </div>
    </div>

    <div slot="total" slot-scope="data">
      <div v-for="(ServicePlanningsMaterial,key) in data" :key="key">
        {{processServicePlanningsMaterialTotals(ServicePlanningsMaterial)}}
        <hr v-if="Object.keys(data).length > 1" class="bg-dark">
      </div>
    </div>

    <div slot="servicePlannings" slot-scope="data">
      <div v-for="(ServicePlanningsMaterial,key) in data" :key="key">
          {{ServicePlanningsMaterial[0].service_plannings.programming_code}}
          <hr v-if="Object.keys(data).length > 1" class="bg-dark">
      </div>
    </div>
<!--    <div slot="workCenter" slot-scope="workCenters">-->
<!--      <span v-if="Object.keys(workCenters).length">-->
<!--        {{workCenters.workCenters.code}} - {{workCenters.workCenters.name}}-->
<!--      </span>-->
<!--    </div>-->
<!--    <div slot="action">-->
<!--      Acciones-->
<!--    </div>-->
  </a-table>
</template>

<script>
import { apiRestMixin } from '@/mixins/apiRestMixin'

export default {
  name: 'servicePlanningMaterialsTable',
  props: {
    tableObject: Object,
    conditionalColumns: {
      required: false,
      default: () => {
        return []
      },
      type: [Array],
    },
  },
  mixins: [apiRestMixin],
  data () {
    return {
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
      columns: [
        {
          title: 'Corriente',
          dataIndex: 'currentGroup',
          key: 'currentGroup',
          align: 'center',
          width: 'auto',
          class: 'small',
          scopedSlots: {
            customRender: 'current',
          },
        },
        {
          title: 'Unidad de Medida',
          dataIndex: 'currentGroup.ums',
          key: 'um',
          align: 'center',
          width: 'auto',
          class: 'small',
          scopedSlots: {
            customRender: 'um',
          },
        },
        {
          title: 'Cantidad Total',
          dataIndex: 'currentGroup.ums',
          key: 'total',
          align: 'center',
          width: 'auto',
          class: 'small',
          scopedSlots: {
            customRender: 'total',
          },
        },
        {
          title: 'Servicios',
          dataIndex: 'currentGroup.ums',
          key: 'servicePlannings',
          align: 'center',
          width: 'auto',
          class: 'small',
          scopedSlots: {
            customRender: 'servicePlannings',
          },
        },
        // {
        //   title: 'Acciones',
        //   dataIndex: '',
        //   key: 'x',
        //   // fixed: 'right',
        //   class: 'small',
        //   align: 'center',
        //   scopedSlots: {
        //     customRender: 'action',
        //   },
        // },
      ],
    }
  },
  watch: {
    searchInput(newValue) {
      this.$emit('searchInput', newValue)
    },
  },
  methods: {
    processServicePlanningsMaterialTotals(ServicePlanningsMaterials) {
      let total = 0
      ServicePlanningsMaterials.forEach(material => {
        total += material.container_capacity * material.container_quantity
      })
      return total
    },
    GoToServicePlanning(data) {
      this.$store.dispatch('servicePlannings/UPDATE_FOLIO', { payload: data.sap_programming_code })
      this.$store.dispatch('servicePlannings/UPDATE_KEY', { payload: data.jsonApi.id })
      this.$router.push({ name: 'service', params: { folio: data.sap_programming_code, id: data.jsonApi.id } })
    },
    expand(expanded, record) {
      // console.log(record)
    },
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    excessPermanence(data) {
      this.$emit('excessPermanenceFunc', data)
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
  },
}
</script>

<style scoped>

</style>
