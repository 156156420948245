<template>
  <b-container fluid class="mt-2">
    <!-- Barra de Control -->
    <div class="row">
      <!--    Filter  -->
      <div class="col-12 col-md-8 co-lg-8 mb-3">
        <b-form-group v-if="showSearch" class="mb-0">
          <div slot="description" class="text-left">
            Ingrese Claves o Números de SAP separados por una coma (,).
            <span class="font-italic">Ej  G_00000XXXXX1, SAP_00000XXXXX1</span>
          </div>
          <b-input-group>
            <b-form-input
              v-model="filterValue"
              type="search"
              id="filterInput"
              placeholder="Buscar por Clave o #SAP"
              @keyup.enter ="FilterData()"
              @keyup.esc ="clear()"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="default"
                        size="sm"
                        :disabled="!filterValue"
                        @click="clear()">
                <b-icon-x/>
              </b-button>
              <b-button variant="primary"
                        size="sm"
                        @click="FilterData()" >
                <b-icon-search/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <!--    Filter  -->
      <!--    Sort & Paginate  -->
      <div :class="showSearch? 'col-12 col-md-4 col-lg-3': 'col-sm-12 col-md-6 col-lg-8'">
        <b-form-group>
          <b-input-group>
            <b-form-select v-model="sortBy" :options="sortByOptions" class="w-50" size="sm" @change="FilterData()" />
            <b-form-select v-model="sortDirection" :options="sortOptions" class="w-25" size="sm" :disabled="!sortBy" @change="FilterData()"/>
            <b-form-select v-model="perPage"  :options="pageOptions" class="w-25" size="sm" @change="FilterData()"/>
            <!--    Others  -->
          </b-input-group>
        </b-form-group>
      </div>
      <div  v-if="!showSearch" class="col-sm-12 col-md-6 col-lg-4">
        <b-button block
                  variant="primary"
                  @click="FilterData()" >
          <b-icon-search/>
        </b-button>
      </div>
      <!--    Sort & Paginate  -->
      <hr class="bg-primary">
      <slot name="filters"/>
      <!--    Others  -->
      <!--    Others  -->
    </div>
  </b-container>
</template>
<script>
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
export default {
  name: 'servicesTableBar',
  props: {
    sortByOptions: Array,
    showSearch: {
      default: false,
      type: [Boolean],
      required: false,
    },
  },
  mixins: [
    getGeneralMixin,
  ],
  data() {
    return {
      filterValue: '',
      sortOptions: [
        { value: '', text: 'Orden', disabled: true },
        { value: '', text: 'Asc' },
        { value: '-', text: 'Desc' },
      ],
      sortBy: '',
      sortDirection: '',
      currentPage: 1,
      perPage: 15,
      pageOptions: [
        { value: 10, text: '#', disabled: true },
        { value: 15, text: '15' },
        { value: 25, text: '25' },
      ],
    }
  },
  watch: {
    filterValue(newValue) {
      this.$emit('searchValue', newValue)
      this.$emit('currentPageValue', 1)
    },
    sortBy(newValue) {
      this.$emit('sortByValue', newValue)
    },
    sortDirection(newValue) {
      this.$emit('sortDirectionValue', newValue)
    },
    perPage(newValue) {
      this.$emit('perPageValue', newValue)
    },
    currentPage(newValue) {
      this.$emit('currentPageValue', newValue)
    },
  },
  methods: {
    FilterData() {
      this.$emit('getData')
    },
    clear() {
      this.filterValue = ''
      this.currentPage = 1
      this.$emit('currentPageValue', 1)
      this.$emit('searchValue', '')
      this.$emit('getData')
    },
  },
}
</script>
